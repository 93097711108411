
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "member-overview",
  components: { GoBackButton },
  props: ["publicId"],
  data() {
    return {
      policySummary: {},
      policies: [
        {
          businessTypeShortCode: "",
          item: { publicId: "" },
          policySummary: {
            year: "",
            number: "",
            reference: "",
            type: "",
            expiryDate: "",
          },
        },
      ],
      profileSummary: {
        ward: "",
        homeAddress: "",
        identificationExpiryDate: "",
        identificationIssueDate: "",
        identificationNumber: "",
        maritalStatus: "",
        meansOfIdentification: "",
        dateOfBirth: "",
        gender: { name: "" },
        state: { name: "" },
        localGovernmentArea: { name: "" },
        alternativePhoneNumber: "",
        phoneNumber: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
      },
      claims: [
        {
          claimNumber: "",
          policyType: "",
          entity: "",
          lossType: "",
          status: "",
          amount: 0,
          settledAmount: 0,
          outstandingAmount: 0,
          lossDate: "",
          reportedDate: "",
          businessTypeShortCode: "",
          publicId: "",
          settlementOffers: [],
          isSettlementOfferAccepted: false,
          isDischargeVoucherApproved: false,
          hasClaimNumber: false,
        },
      ],
      avatarUrl: "",
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs(" Overview", ["Cooperative", "Members"]);
      displayDatatable(["basicExample", "basicExample1"]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      setActiveTab,
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    // Get user personal information on page load
    this.getMemberInformation();

    //Set page title
    document.title =
      "Cooperative: Member Overview | " + process.env.VUE_APP_TITLE;

    // Append route to Avatar URL
    this.avatarUrl = variables.AVATAR_ROUTE;
  },
  methods: {
    //Fetch individual client profile personal infomation
    getMemberInformation() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          variables.COOPERATIVE_MEMBERS_DETAILS_ROUTE + this.publicId
        )
          .then((response) => {
            //Assign data to variables
            this.profileSummary = response.data.data.personalInformation;
            this.policies = response.data.data.policies;
            this.claims = response.data.data.claims;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
